@import './assets/sass/styles.scss';

.App {
  text-align: center;
}



header {
  z-index: 200 !important;
}




.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.textfield {
  color: white !important;
}
.textfield::before {
  border-bottom: 1px solid white !important;
}
.textfield::after {
  border-bottom: 2px solid white !important;
}

.textfield__label {
  color: white !important;
}

.content {
  position: relative;
}

.document-pdf {
  max-height: 650px;
  overflow-y: scroll;
  display: flex;
  justify-content: center;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
  opacity: 1 !important;
  -webkit-text-fill-color: rgba(0, 0, 0, 1) !important;
  color: rgba(0, 0, 0, 1) !important;
}
.MuiButton-root {
  outline: none !important;
}

* {
  outline: none !important;
}

/* width */
::-webkit-scrollbar {
  width: 15px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
// .MuiPaper-root{
//   z-index: 10 !important;
// }
.MuiModal-root{
  z-index: 200 !important;
}

.MuiDrawer-root{
  z-index: 100
}

// .MuiMenu-list {
//   top: 60px !important;
// }

.list-main {
  max-height: calc(100vh - 90px);
  overflow-y: auto;
  overflow-x: hidden;
}

.Mui-disabled {
  opacity: 1 !important;
  -webkit-text-fill-color: black !important;
  color: black !important;
}


.rowSigned {
  background-color: #919fea !important;
}

.view-contract {
  .ck-editor {
    height: 600px !important;
    overflow-y: scroll;
  }
}

