@import "bootstrap";
@import "components/components";
@import "typography";

.ant-table-thead {
  th {
    background-color: #3f51b5 !important;
    color: white !important;
    font-size: 14px;
  }
  th {
    border: 0 !important;
  }
  td {
    border: 0 !important;
    font-size: 14px;
  }
}

.ant-table-cell {
  font-size: 14px !important;
}

.border-table-word {
  border: 1px solid grey !important;
}
